<script setup>
import { ref, computed, watch, reactive } from "vue"
import router from "@/router"
import { useRoute } from "vue-router"
import { useStoreCompany } from "@/stores/store.company"
import { useStoreSite } from "@/stores/store.site"
import env from "@/helpers/Environment"

import CompanyBlock from "@/components/CompanyBlock.vue"
import PanelMenu from "primevue/panelmenu"
import SvgHome from "@/components/svg/SvgHome.vue"
import SvgCorporate from "@/components/svg/SvgCorporate.vue"
import SvgBriefcase from "@/components/svg/SvgBriefcase.vue"
import SvgCard from "@/components/svg/SvgCard.vue"
import SvgMoney from "@/components/svg/SvgMoney.vue"

const route = useRoute()
const storeSite = useStoreSite()
const storeCompany = useStoreCompany()

const rData = reactive({
	loading: true,
	menuItems: [],
})


watch(() => storeCompany.selectedCompany, (v)=>{
	rData.loading = true;
	rData.menuItems = [
		{ label: "Dashboard", to: "/dashboard" },
		{ label: "Profile", to: "/profile" },
		{ label: "Orders", to: "/orders" },
		{
			label: "Corporate Secretarial",
			url: storeCompany.hasCompany() ? env.CORPSEC_HOME_URL : "",
			command:()=>{
				if(!storeCompany.hasCompany()) storeSite.toggleCorpsecOnboardingSideBar();
			},
			// disabled: !storeCompany.hasCompany(),
		},
		// {
		// 	label: "MEYZER Pay",
		// 	items: storeCompany.hasModuleAccess("digitalbank") ? [
		// 		{ label: "Collect", url: env.DIGITAL_BANKING_COLLECT_URL },				
		// 		{ label: "Send", url: `${env.DIGITAL_BANKING_HOME_URL}?redirect=send-fund` },
		// 		{ label: "Deposit", url: `${env.DIGITAL_BANKING_HOME_URL}?redirect=deposit`, disabled: true, badge:"coming soon"  },
		// 		{ label: "Spend", url: `${env.DIGITAL_BANKING_HOME_URL}?redirect=spend`, disabled: true, badge:"coming soon" },
		// 	] : null,
		// 	command:()=>{
		// 		if(!storeCompany.hasModuleAccess("digitalbank")) storeSite.toggleDigitalFinanceOnboardingSideBar();
		// 	},
		// 	// disabled: !storeCompany.hasCompany(),
		// },
		// { label: "Contact Us", url: "/contact", disabled:false },
	]

	rData.loading = false;
}, {immediate:true});



/*** util functions ***/
function isMatchedRoute(routeName) {
	return route.path.includes(routeName.toLowerCase())
}

/*** event handlers ***/
const onClickItem = (event, item) => {
	event.preventDefault()
	if (item.url) {
		window.open(item.url, "_blank")
	} else {
		if(item.to) router.push(item.to)
		//mobile
		storeSite.sideBarFlag = false
	}
}

</script>

<template>
	<div class="card flex flex-column justify-content-center" id="container">
		<CompanyBlock />


		<PanelMenu :model="rData.menuItems" class="w-full text-sm font-normal mt-2" style="padding-left:29px;">
			<template #item="slotProps">
				<div class="relative">

					<!-- {{ slotProps.item }} -->
					<a
						v-if="slotProps.item.items?.length"
						class="flex gap-2 align-items-center p-2 cursor-pointer text-custom-grey-2 border-round-lg ">
						<span
							:class="[
								{ 'text-color-custom': route.path == slotProps.item.items.to },
								{ 'font-custom': slotProps.item.items },
							]">
							{{ slotProps.item.label }}
						</span>
						<i class="pi pi-angle-down ml-auto"></i>
					</a>
					<a
						v-else
						class="flex gap-2 align-items-center p-2 cursor-pointer no-underline text-custom-grey-2 font-custom"
						:class="[slotProps.item.disabled ? 'menuitem-custom-disabled' : '']"
						:href="slotProps.item.to"
						@click="(e) => onClickItem(e, slotProps.item)" >
						<span :class="[{ 'font-custom': slotProps.item.items, 'text-primary' : isMatchedRoute(slotProps.item.label) }]">{{ slotProps.item.label }}</span>

						<Badge v-if="slotProps.item.badge" class="ml-auto" :value="slotProps.item.badge" />
					</a>
				</div>
			</template>
		</PanelMenu>

	</div>
</template>

<style scoped lang="scss">
.font-custom {
	font-weight: 600;
	color: rgba(0,0,0,0.7) !important
}
.company-btn-custom {
	background-color: transparent;
	border: 1px solid transparent;
	overflow: visible;
	background-color:#f5f6f8;
	border-radius:8px;

	&.p-button {
		background-color: transparent;
		&:hover {
			background-color:#f5f6f8;
			border: 1px solid transparent;
		}
	}
}

.is-disabled {
	pointer-events: none;
	opacity:0.5;
}

/* company menu */
.p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    // color: #495057;
    background: #f5f6f8 !important;
}

/* child item */
:deep(.p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover) {
	background-color:#f5f6f8;
	border-radius:8px;

}

/* active menu */
:deep(.p-panelmenu .p-panelmenu-header:not(.p-disabled).p-highlight .p-panelmenu-header-content) {
	background:transparent;
	border-color: transparent;
	border-radius:8px;

	&:hover {
		background:#f5f6f8;
		border-color: transparent;
	}
}

/* parent not active*/
:deep(.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover .p-panelmenu-header-content) {
	background:#f5f6f8;
	border-radius:8px;
} 

:deep(.p-menuitem-content a) {
	padding-left:1.5rem !important;
}

:deep(.p-panelmenu .p-panelmenu-content) {
	background-color: transparent;
	border: 1px solid transparent;
}

:deep(.p-panelmenu .p-panelmenu-header .p-panelmenu-header-content) {
	border: 1px solid transparent;
	background-color: transparent;
}


:deep(.p-panelmenu .p-panelmenu-panel) {
	border:1px solid transparent;
	padding:0;
	margin-bottom:0;
}
</style>
