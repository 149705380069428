import { defineStore } from 'pinia'
import * as accountService from '@/services/service.account';
import { useStoreAccess } from "@/stores/store.access";
import { useStoreSite } from "@/stores/store.site";
import { showToast } from '@/components/Notification';
import router from '@/router';
import _ from 'lodash';
import ShareFunc from "@/helpers/ShareFunc";
import * as Account from '@/models/model.account';
import { customJoi as Joi, customJoi, prepareJoiErrorArr } from '@/models/customJoi';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import Constant from "@/helpers/Constant";
import env from "@/helpers/Environment";
import qs from 'qs';
import Utils from '@/helpers/Utils';
import { useStoreIndividual } from "@/stores/store.individual"

// user data from login api
const objUser = { userId: null, firstName: null, lastName: null, mobileFull: null, idToken: null, accessToken: null, refreshToken: null };
const objState = { user: objUser, notification: null };

export const useStoreAccount = defineStore('account', {
  state: () => (_.cloneDeep(objState)),
  //state: () => ({user:{firstName: "", lastName: "", salutation:"", newUser:true}}),
  getters: {
    isLoggedIn: (state) => state.user?.userId != null,
    username: (state) => {
      let fn = state.user.firstName;
      let ln = state.user.lastName;
      let n = fn && ln ? `${fn} ${ln}` : fn && !ln ? ` ${fn}` : '';
      return Utils.formatTitleCase(n);
      //return n;
    },
    kycStatus: (state) => {
      const storeIndividual = useStoreIndividual();
      if(state.user.kycVerified === 'not verified'){
        return state.user.kycVerified;
      }
      if(state.user.individualId){
        return storeIndividual?.individual?.kycStatus || 'pending review'
      }else {
        return state.user.kycVerified || 'not verified';
      }
    }
  },
  actions: {
    reset() {
      this.user = _.cloneDeep(objState);
      this.notification = null;
    },
    oidcLogin(data) {
      //console.log("oidcLogin", data)
      //ShareFunc.track("account-sign-in-auth-code", "account", "sign-in");
      return accountService.oidcLogin(data)
        .then((res) => {
          //console.log("loginOidc", res);
          this._afterLogin(res.data);
          return res;
        })
        .catch((err) => {
          //console.log('oidcLoginFailure: ', err);
          throw err;
        });
    },
    logout() {
      //ShareFunc.track("account-logout", "account", "logout");
      this.reset();
      accountService.logout()
        .then((res) => {
          //console.log('logout', res);
          //this.user = defaultUserData;
        }).catch((err) => {
          //console.log('logout err', err);
        });
    },
    resumeLogin() {
      //console.log("resumeLogin");
      //ShareFunc.track("account-resume-login", "account", "resume-login");
      return accountService.resumeLogin()
        .then((res) => {
          const data = res.data;
          this._afterLogin(data);
          return res;
        })
        .catch((err) => {
          //console.log(err);
          this.error = err
          return err;
        });
    },
    refreshToken() {
      // loynote: refresh token via cookie's refreshtoken withCredential = true;
      return accountService.refreshToken()
        .then((res) => {
          const data = res.data;
          this._afterLogin(data);
          return res;
        })
        .catch((err) => {
          //console.log("refreshTokenFailure: ", err);
          return err;
        });
    },
    async _afterLogin(data) {
      
      const decoded = jwt_decode(data.accessToken);
      const decoded2 = jwt_decode(data.idToken);
      //
      //console.log('data', data);
      //console.log('accesstoken', decoded);
      //console.log("idtoken", decoded2)
      
      this.user.userId = decoded.sub;
      this.user.mobileFull = decoded2.mobileFull;
      this.user.mobileCountryCode = decoded2.mobileCountryCode;
      this.user.mobile = decoded2.mobile;
      this.user.salutation = decoded.salutation;
      this.user.fullName = decoded2.fullName;
      this.user.firstName = decoded2.firstName;
      this.user.lastName = decoded2.lastName;
      this.user.displayPicture = decoded2.displayPicture;
      //console.log('[displaypic]', this.user.displayPicture)
      this.user.kycVerified = decoded2.kycVerified;
      this.user.confirmedStatus = decoded2.confirmedStatus;
      this.user.individualId = decoded2.individualId;
      this.user.kycStatus = decoded2.kycStatus;
      this.user.kycStage = decoded2.kycStage;
      
      this.user.idToken = data.idToken;
      this.user.accessToken = data.accessToken;
      this.user.email = decoded2.email;

      //console.log("_afterLogin", this.user);
      Cookies.set(Constant.COOKIE_WAS_LOGGED_IN, 'true');
      //Cookies.set(Constant.COOKIE_REFRESH_TOKEN, data.refreshToken);
      // //console.log("user",this.user.data);
      
      const storeAccess = useStoreAccess();
      await storeAccess.getUserAccess(this.user.userId); //getAccess will get companies
      //await this.checkNotification();
      if(this.user.individualId){
        const storeIndividual = useStoreIndividual()
        storeIndividual.getIndividualById(this.user.individualId);
      }

      await Utils.sleep(2000);
      //if (this.notification.length >= 1) {
        // const storeSite = useStoreSite();
        // storeSite.showNotificationDialog();
      //}

    },
    async checkNotification() {
      return accountService.checkNotification().then((res) => {
        this.notification = res.data;
        //console.log("checkNotification", this.notification);
        return res.data;
      }).catch((err) => {
        return err;
      })
    },
    updatePassword(data) {
      const storeSite = useStoreSite();
      return accountService.updatePassword(data).then((res) => {
        //console.log('updatePassword res', res)
        storeSite.showToast("Success", "Update Password Success", "success", 3000)
        return res
      }).catch((err) => {
        //console.log('updatePassword err', err)
        storeSite.showToast("Failed", err?.data?.validation || err?.message || "Update Password Failed", "error", 3000)
        return err;
      })
    },
    async updateProfile(data) {
      //console.log("updateProfile", data)
      // if (data.displayPicture.key == "") {
      //   delete data.displayPicture;
      // }
      return accountService.updateProfile(data)
        .then((res) => {
          return true;
        })
        .catch((err) => {
          throw err;
        });
    },


  },
});