export const cn = {
	"Start Registration": (ctx) => "开始注册",
	"Register your business in Singapore": (ctx) => "在新加坡注册您的公司",
	"Enjoy hassle-free service and responsive support. Register your business 100% online with us today.": (ctx) =>
		"享受无忧的服务和响应迅速的支持。立即向我们 100% 在线注册您的企业。",
	"Start Registration": (ctx) => "开始注册",
	"Save Form Draft": (ctx) => "保存表单草稿",
	"This registration takes approximately 15 minutes. You may save your progress and resume your form application at your convenience.":
		(ctx) => "此注册大约需要 15 分钟, 您可以保存进度并在方便时继续填写表格申请。",
	"This registration takes approximately 15 minutes. Your progress will be saved and you may resume your form application at your convenience.":
		(ctx) => "此注册过程大约需要 15 分钟。您的进度将被保存，您可以在方便时恢复您的表格申请。",

	"Company Details": (ctx) => "公司详情",
	"Members / Shareholders Details": (ctx) => "成员/股东详情",
	"Officers Details": (ctx) => "官员详情",
	Constitution: (ctx) => "宪法",
	"Financial Year End": (ctx) => "财政年度结束",
	Declaration: (ctx) => "宣言",

	/* Form.CorpsecRegNewCoCombine - step 1 */
	"PROPOSED COMPANY NAME": (ctx) => "公司名称",
	"Please provide up to 3 options.": (ctx) => "请提供最多 3 个选项",
	"Company Name": (ctx) => "公司名称",
	"Company Name (1st choice)": (ctx) => "公司名称（第一选择)",
	"Company Name (2nd choice)": (ctx) => "公司名称（第二选择)",
	"Company Name (3rd choice)": (ctx) => "公司名称（第三选择）",
	"Company name subjects to approval by ACRA": (ctx) => "公司名称须经 ACRA 批准",
	"ISSUED & PAID UP CAPITAL": (ctx) => "已发行及实收资本",
	"What is your base currency": (ctx) => "您的基础货币是什么",
	"Base Currency": (ctx) => "基础货币",
	"Please specify your issued & paid up capital.": (ctx) => "请注明您的已发行资本和实收资本",
	"Capital Amount": (ctx) => "资本金额",
	"If the paid up capital is $5,000 & above (in any currency), to produce either the bank remittance advice or the cheque image for proof of payment by shareholder(s).":
		(ctx) => "如果实收资本为 5,000 美元及以上（任何货币），则需出示银行汇款单或支票图像作为股东付款证明。",
	"MAIN PRINCIPAL ACTIVITIES": (ctx) => "主要业务活动",
	"Primary Activity": (ctx) => "主要公司活动",
	"Secondary Activity": (ctx) => "次要公司活动",
	"REGISTERED ADDRESS": (ctx) => "注册地址",
	"Please state if the registered address is at": (ctx) => "请注明注册地址是否为",
	"The registered address is different.": (ctx) => "注册地址不同",
	"Address 1": (ctx) => "地址第一行",
	"Address 2": (ctx) => "地址第二行",
	"Postal Code": (ctx) => "邮政编码",
	Next: (ctx) => "下一步",

	/* Form.CorpsecRegNewCoCombine - step 2 */
	"State the names of two (2) Directors if member is a corporation. A common seal is required to be affixed in the constitution.":
		(ctx) => "如果成员是公司，请注明两 (2) 名董事的姓名。章程中需要加盖法团印章。",
	"Add Member": (ctx) => "添加会员",
	"Name (as per NRIC/Passport/ACRA)": (ctx) => "姓名（根据身份证/护照/ACRA)",
	"NRIC/Passport/ACRA No.": (ctx) => "身份证/护照/ACRA 号码",
	"Mobile Number": (ctx) => "手机号码",
	"Residential Address": (ctx) => "居住地址",
	"No of Ordinary Shares Held": (ctx) => "持有普通股数量",
	Cancel: (ctx) => "取消",
	Email: (ctx) => "电子邮件",
	"Full Name": (ctx) => "全名",
	"Share  Amount": (ctx) => "股份数量",
	Update: (ctx) => "更新",
	Add: (ctx) => "添加",

	/* Form.CorpsecRegNewCoCombine - step 3 */
	"DIRECTOR DETAILS": (ctx) => "公司董事详细信息",
	"Note: Nominee Director service is available upon request": (ctx) => "注：可根据要求提供名义董事服务",
	"COMPANY SECRETARY": (ctx) => "公司秘书",
	"Unless you specifically inform us otherwise in writing, we will proceed to appoint our in-house officer as default.":
		(ctx) => "除非您以书面形式明确通知我们，否则我们将继续任命我们的内部官员作为默认人员",
	"I understand and agree with the above.": (ctx) => "我理解并同意上述内容。",
	"OTHER DIRECTORSHIPS": (ctx) => "其他董事职务",
	"Please specify the names of companies, that any directors hold directorships of.": (ctx) =>
		"请注明任何董事担任董事职务的公司名称。",
	"Add Director": (ctx) => "添加公司董事",
	"I hereby confirm that the above directors had not been the director of not less than 3 companies which were struck off within 5-year period commencing from 3 January 2014 as a consequence of review exercise by the ACRA.":
		(ctx) =>
			"本人特此确认，上述董事并非不少于 3 家公司的董事，这些公司在自 2014 年 1 月 3 日起的 5 年内因 ACRA 的审查而被除名",
	"I confirm that, according to Section 155A of the Companies Act 1967, none of the above directors’ companies have been struck off the register by ACRA under Section 344 within the last 5 years. The 5-year period starts from the date the 3rd company is struck off.":
		(ctx) =>
			"我确认，根据 1967 年公司法第 155A 条，上述董事的任何公司在过去 5 年内均未根据 ACRA 第 344 条从登记册中删除。 5年期限自第三家公司注销之日起计算。",
	"I understand that if 3 or more companies are struck off by ACRA within 5 years, the directors will be disqualified from acting as directors or managing any company for 5 years.":
	(ctx) =>
		"据我了解，如果5年内有3家或以上公司被ACRA除牌，该董事将被取消担任董事或管理任何公司的资格5年。",
	"I confirm that the information provided is true and correct to the best of my knowledge.":
	(ctx) =>
		"我确认，据我所知，所提供的信息是真实且正确的。",
	"Enclose clear photocopies of identity cards (both sides) / passports for all Directors/Shareholders together with proof of residential address if passports are furnished.":
		(ctx) => "附上所有董事/股东的身份证（双面）/护照的清晰复印件以及居住地址证明（如果提供护照）。",

	/* Form.CorpsecRegNewCoCombine - step 4 */
	"COMPANY CONSTITUION": (ctx) => "公司章程",
	"Companies are incorporated with our standard Constitution, unless otherwise instructed, an additional fee, based on time cost and complexity of job, will be raised for customization of Constitution.":
		(ctx) => "公司按照我们的标准章程成立，除非另有说明，否则将根据时间成本和工作复杂性收取额外费用以定制章程。",
	"I understand and agree with the above.": (ctx) => "我理解并同意上述内容。",

	/* Form.CorpsecRegNewCoCombine - step 5 */
	FYE: (ctx) => "财政年度结束",
	" Please decide on your company financial year end": (ctx) => "请决定贵公司的财政年度结束日期",
	"Deciding on the FYE is very important as it will determine when your corporate filings and taxes are due every year.":
		(ctx) => "决定财政年度非常重要，因为它将决定您每年的公司申报和纳税到期时间。",
	"Select Date": (ctx) => "选择日期",

	/* Form.CorpsecRegNewCoCombine - step 6 */
	"DECLARATION BY APPLICANT": (ctx) => "申请人声明",
	"Please enter your Name, NRIC/Passport number and Email": (ctx) => "请输入您的姓名、身份证/护照号码和电子邮件",
	Name: (ctx) => "姓名",
	"NRIC / Passport No.": (ctx) => "身份证/护照号码",
	Email: (ctx) => "电子邮件",
	I: (ctx) => "我",
	name: (ctx) => "姓名",
	"of NRIC / Passport No.": (ctx) => "身份证/护照号码",
	email: (ctx) => "电子邮件",
	"on behalf of the Board of Directors of the proposed company, hereby declare that:": (ctx) =>
		"代表拟建公司董事会特此声明：",
	"the Director(s) is/are not a bankrupt": (ctx) => "董事并非破产人",
	"the member who is a corporation/LLP, is not under liquidation (if applicable)": (ctx) =>
		"作为公司/有限责任合伙企业的成员未处于清算状态（如果适用）",
	"the particulars and information including documents, furnished by me in respect of this application form are true and correct to the best of my knowledge and belief, and that I have not willfully suppressed any material fact.":
		(ctx) =>
			"据我所知和所信，我就本申请表提供的详细信息和信息（包括文件）均真实、正确，并且我没有故意隐瞒任何重要事实。",
	"Please tick checkbox to declare.": (ctx) => "请勾选复选框进行申报。",

    
    /* miscellaneous */
	"Shareholder Type":(ctx) => "股东类型",
	"Individual":(ctx)=>"个人",
	"Corporate":(ctx)=>"公司",
	
	"Full Name (as in Certificate of Incorporation)": (ctx) => "注册证书上的全名",
	"Registered Office Address": (ctx) => "注册办公地址",
	"Date of Incorporation": (ctx)=> "成立日期",
	"Country of Incorporation": (ctx)=> "成立国家",
	"Company Registration No.": (ctx)=> "公司注册号",
	"Name of Authorised Corporate Representative":(ctx)=>"授权公司代表姓名",

	"Drag and drop files here": (ctx) => "将文件拖放到此处",
	"Submit": (ctx) => "提交",
	"Shares": (ctx) => "公司股份",
	"Identification Number": (ctx) => "身份证号码",
	"Date of Birth": (ctx) => "出生日期",
	"DOB": (ctx) => "出生日期",
	"Mobile": (ctx) => "手机号码",
	"Country": (ctx) => "国家",
	"Do you really want to proceed?": (ctx) => "您真的想继续吗？",
	"Nationality": (ctx) => "国籍",
	"Alternate Address (optional)": (ctx) => "备用地址（可选）",
	"Email Address": (ctx) => "电子邮件地址",
	"Other Positions Held": (ctx) => "担任过的其他职务",
	"Chief Executive Officer/Managing Director/Secretary (if applicable)": (ctx) => "首席执行官/董事总经理/秘书（如果适用）",
	"Add Director": (ctx) => "添加公司董事",
	"Country": (ctx) => "国家",
	"Complete": (ctx) => "完成",
	"Get Link": (ctx) => "获取链接",
	"Save Draft": (ctx) => "保存草稿",
	"We will save your progress and send you a link to continue your form application at your convenience.": (ctx) =>
		"我们将保存您的进度，并向您发送一个链接，以便您在方便时继续填写表格申请。",
	"Enter your email": (ctx) => "输入你的电子邮箱",
	"Save Email": (ctx) => "保存电子邮件",
	'Form submitted successfully': (ctx) => "表单提交成功",
	'Thank You': (ctx) => "谢谢你",
	'Back to Home': (ctx) => "返回首页",
	'Submitting': (ctx) => "正在提交",
	'Application Processing Time.': (ctx) => "申请处理时间",
	'Processing will typically takes 2-3 business days.': (ctx) => "处理通常需要 2-3 个工作日。",
	"Please correct form error(s) in Section 1.":(ctx) => "请更正第 1 节中的表格错误。",
	"Please correct form error(s) in Section 2.":(ctx) => "请更正第 2 节中的表格错误。",
	"Please correct form error(s) in Section 3.":(ctx) => "请更正第 3 节中的表格错误。",
	"Please correct form error(s) in Section 4.":(ctx) => "请更正第 4 节中的表格错误。",
	"Please correct form error(s) in Section 5.":(ctx) => "请更正第 5 节中的表格错误。",
	"Please correct form error(s) in Section 6.":(ctx) => "请更正第 6 节中的表格错误。",

	'Draft saved': (ctx) => "草稿已保存",
	'Email sent': (ctx) => "邮件已发送",
	
	'Click here for help with MSIC description': (ctx)=>'单击此处获取有关 MSIC 描述的帮助',


	// Digital Finance Application form CN ver
	"Do you require any of these services?": (ctx) => "您需要这些服务吗？",
	"Cross Border": (ctx) => "跨境转账",
	"Payment Link": (ctx) => "付款链接",
	"Virtual Terminal": (ctx) => "虚拟支付终端",
	"in SGD": (ctx) => "新元",
	"MEYZER360 Digital Banking Application": (ctx) => "MEYZER360 数字银行应用",
	"MEYZER360 Digital Finance Application": (ctx) => "MEYZER360 数字金融应用",
	"Banking at your fingertips with our seamless digital banking services.": (ctx) => "数字银行服务触手可及",
	"Banking at your fingertips with our seamless digital finance services.": (ctx) => "数字金融服务触手可及",
	"Start Applying": (ctx) => "开始申请",
	"This registration takes approximately 5 - 10 minutes. You may save your progress and resume your form application at your convenience.":
		(ctx) => "整个注册流程会需要大约5至10分钟。您可以保存现有的资料，在方便的时候继续您的注册流程。",
	"This registration takes approximately 5 - 10 minutes. Your progress will be saved and you may resume your form application at your convenience.":
		(ctx) => "整个注册流程会需要大约5至10分钟。您可以保存现有的资料，在方便的时候继续您的注册流程。",
	"Please have your business and personal identification documents ready for upload during registration, including:":
		(ctx) => "请在注册前准备好以下公司资料和个人资料以便上传：",
	"ACRA/Bizfile Profile (dated no longer than 3 months ago)":
		(ctx) => "新加坡会计与企业管理局（ACRA）/在线信息检索和归档（Bizfile）的公司简介（不超过3个月）",
	"Company Memorandum and Articles of Association or Constitution (for Private Limited)":
		(ctx) => "公司备忘录和章程（适用于私人有限公司）",
	"Partnership Agreement (for LP/LLP)":
		(ctx) => "合伙协议（适用于有限合伙/有限责任合伙）",
	"Proof of address (for foreigners)":
		(ctx) => "地址证明（适用于外国人）",
	"Details of your directors, shareholders, owners, partners, and authorised users (wherever applicable)":
		(ctx) => "董事、股东、所有者、合伙人和授权用户的详细信息（如适用）",
	"ID copies of your directors":
		(ctx) => "董事的身份证复印件",
	"Company Information":
		(ctx) => "公司信息",
	"To begin, please furnish us with the following details.":
		(ctx) => "首先，请向我们提供以下详细信息",
	"What is your company's name?":
		(ctx) => "贵公司的名字是什么？",
	"Company Name": (ctx) => "公司名字",
	"What is your company's trading name? ":
		(ctx) => "贵公司的商业名称是什么？",
	"Only required if different from company name." : (ctx) => "仅当与公司名称不同时才需要",
	"What is your company's trading name? (if different from company name)":
		(ctx) => "贵公司的商业名称是什么？（如果不同于公司名字）",
	"Company Trading Name":
		(ctx) => "公司商业名称",
	"What is your company's registration number?":
		(ctx) => "贵公司的注册号码是什么？",
	"Company Registration Number":
		(ctx) => "公司注册号码",
	"What is your company's website?":
		(ctx) => "贵公司的网站地址是什么？",
	"Company Website": (ctx) => "公司网站",
	"What is your company's entity type?":
		(ctx) => "贵公司的实体类型是什么？",
	"Entity Type": (ctx) => "实体类型",
	"Is your business operations address different from your registered address as per ACRA?":
		(ctx) => "贵公司的营业地址与您在新加坡会计与企业管理局（ACRA）的注册地址是否有不同？",
	"Yes/No": (ctx) => "是/否",
	"Yes": (ctx) => "是",
	"No": (ctx) => "否",
	"What is your company's industry?":
		(ctx) => "贵公司所属行业是什么？",
	"Please provide your company's operations address?":
		(ctx) => "请提供贵公司的运营地址？",
	"State":
		(ctx) => "地区",
	"City":
		(ctx) => "城市",
	"Main Industry": (ctx) => "主要行业",
	"Next": (ctx) => "下一步",
	"Company Questions":
		(ctx) => "公司相关问题",
	"Describe in specific what are your business activities and business model.":
		(ctx) => "请具体描述您的业务活动和商务模式。",
	"Please include specific details including the type of products you are selling, logistics, warehouse etc.":
		(ctx) => "请提供具体详细信息，包括您销售的产品类型、物流、仓库等。",
	"What is your expected annual turnover volume/revenue in SGD?":
		(ctx) => "您预计的年营业额/收入(新元)是多少？",
	"Expected Annual Turnover":
		(ctx) => "预计的年营业额/收入",
	"In which countries do you have operations?":
		(ctx) => "您在哪些国家开展业务?",
	"Select all that apply.":
		(ctx) => "请选择所有适用项?",
	"Select all that applies.":
		(ctx) => "请选择所有适用项?",
	"Select countries": (ctx) => "请选择国家",
	"Which are your major trading currencies?":
		(ctx) => "您主要的交易货币是什么?",
	"Select currencies": (ctx) => "请选择货币",
	"What do you mainly use your digital bank account for?":
		(ctx) => "您的数字银行账户主要用来做什么?",
	"What is the source of fund?":
		(ctx) => "您的资金来源是什么?",
	"Source Of Fund":
		(ctx) => "资金来源",
	"What do you mainly use your digital finance account for?":
		(ctx) => "您的数字金融账户主要用来做什么?",
	"Select all relevant purposes": (ctx) => "请选择相关用途",
	"How much do you expect to transact using this account in the next 12 months?":
		(ctx) => "您预计在未来12个月内使用此账户进行多少交易？",
	"Expected Transaction Amount":
		(ctx) => "预计交易金额",
	"Personnel Information":
		(ctx) => "个人信息",
	"Company Designation":
		(ctx) => "正式职务",
	"Designation":
		(ctx) => "职务名称",
	"You must submit details of at least 2 directors (unless your company only has 1 director) and all Authorised Users to proceed. All email addresses and mobile numbers must be unique.":
		(ctx) => "为了继续注册流程，您需要提交至少2名董事（除非您的公司只有1名董事）和所有授权用户的详细信息。所有电子邮件地址和手机号码必须是唯一的。",
	"Add Personnel": (ctx) => "添加人员",
	"Personnel Type": (ctx) => "人员类型",
	"Director": (ctx) => "董事",
	"Authorised User": (ctx) => "授权用户",
	"Full Name as per ID": (ctx) => "身份证全名",
	"Full Name": (ctx) => "全名",
	"NRIC / Passport No.": (ctx) => "身份证/护照号码",
	"Identification Number": (ctx) => "证件号码",
	"Email Address": (ctx) => "邮箱地址",
	"Email": (ctx) => "邮箱",
	"Mobile Number": (ctx) => "手机号码",
	"Mobile": (ctx) => "手机号码",
	"Cancel": (ctx) => "取消",
	"Add": (ctx) => "确认添加",
	"Document Upload":
		(ctx) => "上传文件",
	"Please upload the corresponding required documents below to facilitate account opening:":
		(ctx) => "请上传以下相应所需文件，以方便开户：",
	"Please upload your ACRA/Bizfile (dated no longer than 3 months ago).":
		(ctx) => "请上传您的新加坡会计与企业管理局（ACRA）/在线信息检索和归档（Bizfile）的公司简介（不超过3个月）",
	"Drag and drop files here":
		(ctx) => "请将文件拖放到此处",
	"Please upload your Memorandum and Articles of Association or Constitution (Pte Ltd) or Partnership Agreement (LP).":
		(ctx) => "请上传您的公司备忘录和章程（适用于私人有限公司）或合伙协议（适用于有限合伙）",
	"Please upload ID copies of all other directors not provided in Personnel Information. We will reach out to you to perform ID verification of other key persons.":
		(ctx) => "请上传个人信息未提供的所有其他董事的身份证副本。我们将联系您对其他关键人员进行身份验证。",
	"If any of your shareholders/owners/partners are corporate entities, please upload the respective company profiles showing the directors and shareholders of those entities. You may skip this if all your major shareholders are natural persons.":
		(ctx) => "如果您的股东/所有者/合作伙伴中有任何公司实体，请上传相关公司资料以显示这些实体的董事和股东。如果您的所有主要股东都是自然人，您可以跳过此步骤。",
	"Submit": (ctx) => "提交",
	"Complete": (ctx) => "完成",


	// Industry Dropdown List
	"Accounting": (ctx) => "会计",
	"Airlines/Aviation": (ctx) => "航空公司/航空业",
	"Animation": (ctx) => "动画",
	"Apparel and Fashion": (ctx) => "服装和时尚",
	"Architecture": (ctx) => "建筑",
	"Arts and Crafts": (ctx) => "艺术和工艺",
	"Automotive": (ctx) => "汽车",
	"Aviation and Aerospace": (ctx) => "航空和航天",
	"Banking": (ctx) => "银行业",
	"Biotechnology": (ctx) => "生物技术",
	"Broadcast Media": (ctx) => "广播媒体",
	"Building Materials": (ctx) => "建筑材料",
	"Business Supplies and Equipment": (ctx) => "商业用品和设备",
	"Capital Markets": (ctx) => "资本市场",
	"Chemicals": (ctx) => "化学品",
	"Civic and Social Organization": (ctx) => "公民和社会组织",
	"Civil Engineering": (ctx) => "土木工程",
	"Commercial Real Estate": (ctx) => "商业房地产",
	"Computer and Network Security": (ctx) => "计算机和网络安全",
	"Computer Games": (ctx) => "电子游戏",
	"Computer Hardware": (ctx) => "计算机硬件",
	"Computer Networking": (ctx) => "计算机网络",
	"Computer Software": (ctx) => "计算机软件",
	"Construction": (ctx) => "建筑",
	"Consumer Electronics": (ctx) => "消费电子",
	"Consumer Goods": (ctx) => "消费品",
	"Consumer Services": (ctx) => "消费者服务",
	"Cosmetics": (ctx) => "化妆品",
	"Defense": (ctx) => "国防",
	"Design": (ctx) => "设计",
	"Education Management": (ctx) => "教育管理",
	"E-Learning": (ctx) => "电子学习",
	"Electrical/Electronic Manufacturing": (ctx) => "电气/电子制造",
	"Entertainment": (ctx) => "娱乐",
	"Environmental Services": (ctx) => "环境服务",
	"Events Services": (ctx) => "活动服务",
	"Executive Office": (ctx) => "行政办公室",
	"Facilities Services": (ctx) => "设施服务",
	"Farming": (ctx) => "农业",
	"Financial Services": (ctx) => "金融服务",
	"Fine Art": (ctx) => "美术",
	"Fishery": (ctx) => "渔业",
	"Food and Beverages": (ctx) => "食品和饮料",
	"Food Production": (ctx) => "食品生产",
	"Fund-Raising": (ctx) => "筹款",
	"Furniture": (ctx) => "家具",
	"Glass": (ctx) => "玻璃",
	"Government Administration": (ctx) => "政府行政",
	"Government Relations": (ctx) => "政府关系",
	"Graphic Design": (ctx) => "平面设计",
	"Health": (ctx) => "健康",
	"Higher Education": (ctx) => "高等教育",
	"Hospital and Health Care": (ctx) => "医院和医疗保健",
	"Hospitality": (ctx) => "款待",
	"Human Resources": (ctx) => "人力资源",
	"Import and Export": (ctx) => "进出口",
	"Individual and Family Services": (ctx) => "个人和家庭服务",
	"Industrial Automation": (ctx) => "工业自动化",
	"Information Services": (ctx) => "信息服务",
	"Information Technology and Services": (ctx) => "信息技术和服务",
	"Insurance": (ctx) => "保险",
	"International Affairs": (ctx) => "国际事务",
	"International Trade and Development": (ctx) => "国际贸易和发展",
	"Internet": (ctx) => "互联网",
	"Investment Banking": (ctx) => "投资银行",
	"Investment Management": (ctx) => "投资管理",
	"Judiciary": (ctx) => "司法",
	"Law Enforcement": (ctx) => "执法",
	"Law Practice": (ctx) => "法律实践",
	"Legal Services": (ctx) => "法律服务",
	"Legislative Office": (ctx) => "立法办公室",
	"Leisure": (ctx) => "休闲",
	"Libraries": (ctx) => "图书馆",
	"Logistics and Supply Chain": (ctx) => "物流和供应链",
	"Luxury Goods and Jewelry": (ctx) => "奢侈品和珠宝",
	"Machinery": (ctx) => "机械",
	"Management Consulting": (ctx) => "管理咨询",
	"Maritime": (ctx) => "海事",
	"Marketing and Advertising": (ctx) => "市场营销和广告",
	"Market Research": (ctx) => "市场研究",
	"Mechanical or Industrial Engineering": (ctx) => "机械或工业工程",
	"Media Production": (ctx) => "媒体制作",
	"Medical Devices": (ctx) => "医疗设备",
	"Medical Practice": (ctx) => "医疗实践",
	"Mental Health Care": (ctx) => "心理健康护理",
	"Military": (ctx) => "军事",
	"Mining and Metals": (ctx) => "采矿和金属",
	"Motion Pictures and Film": (ctx) => "电影制作",
	"Museums and Institutions": (ctx) => "博物馆和机构",
	"Music": (ctx) => "音乐",
	"Nanotechnology": (ctx) => "纳米技术",
	"Newspapers": (ctx) => "报纸",
	"Nonprofit Organization Management": (ctx) => "非营利组织管理",
	"Oil and Energy": (ctx) => "石油和能源",
	"Online Media": (ctx) => "在线媒体",
	"Outsourcing/Offshoring": (ctx) => "外包/离岸外包",
	"Package/Freight Delivery": (ctx) => "包裹/货运交付",
	"Packaging and Containers": (ctx) => "包装和容器",
	"Paper and Forest Products": (ctx) => "纸和森林产品",
	"Performing Arts": (ctx) => "表演艺术",
	"Pharmaceuticals": (ctx) => "制药",
	"Philanthropy": (ctx) => "慈善事业",
	"Photography": (ctx) => "摄影",
	"Plastics": (ctx) => "塑料",
	"Political Organization": (ctx) => "政治组织",
	"Primary/Secondary Education": (ctx) => "小学/中学教育",
	"Printing": (ctx) => "印刷",
	"Professional Training and Coaching": (ctx) => "专业培训和辅导",
	"Program Development": (ctx) => "程序开发",
	"Public Policy": (ctx) => "公共政策",
	"Public Relations and Communications": (ctx) => "公共关系和通信",
	"Public Safety": (ctx) => "公共安全",
	"Publishing": (ctx) => "出版",
	"Railroad Manufacture": (ctx) => "铁路制造",
	"Ranching": (ctx) => "牧场",
	"Real Estate": (ctx) => "房地产",
	"Recreational Facilities and Services": (ctx) => "娱乐设施和服务",
	"Religious Institutions": (ctx) => "宗教机构",
	"Renewables and Environment": (ctx) => "可再生能源和环境",
	"Research": (ctx) => "研究",
	"Restaurants": (ctx) => "餐馆",
	"Retail": (ctx) => "零售",
	"Security and Investigations": (ctx) => "安全和调查",
	"Semiconductors": (ctx) => "半导体",
	"Shipbuilding": (ctx) => "造船",
	"Sporting Goods": (ctx) => "运动用品",
	"Sports": (ctx) => "体育",
	"Staffing and Recruiting": (ctx) => "人员配备和招聘",
	"Supermarkets": (ctx) => "超市",
	"Telecommunications": (ctx) => "电信",
	"Textiles": (ctx) => "纺织品",
	"Think Tanks": (ctx) => "智库",
	"Tobacco": (ctx) => "烟草",
	"Translation and Localization": (ctx) => "翻译和本地化",
	"Transportation/Trucking/Railroad": (ctx) => "运输/卡车/铁路",
	"Utilities": (ctx) => "公用事业",
	"Venture Capital and Private Equity": (ctx) => "风险投资和私募股权",
	"Veterinary": (ctx) => "兽医",
	"Warehousing": (ctx) => "仓储",
	"Wholesale": (ctx) => "批发",
	"Wine and Spirits": (ctx) => "葡萄酒和烈酒",
	"Wireless": (ctx) => "无线",
	"Writing and Editing": (ctx) => "写作和编辑",
	"Holding Company": (ctx) => "控股公司",
	"Office Administrative Services": (ctx) => "办公室行政服务",
	"Childcare": (ctx) => "儿童保育",
	"Other": (ctx) => "其他"
	
	
	
	
}
